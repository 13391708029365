import React, { useState } from "react";
import {
  Box,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Typography,
  Grid,
  LinearProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {
  Layout, TopNavigation, VideoList
} from "components";
import CloseIcon from "@material-ui/icons/Close";
import serviceHelper from "helpers/serviceHelper";
let timer;

export default function Info({ location }) {
  const [search, setSearch] = useState("");
  const [searchedItem, setSearchedItem] = useState("");
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const seo = {
    title: "DOCUMENTARY+",
    description: `Home to the world’s best documentaries -- from Academy 
    Award winning classics and box office hits to festival darlings and cult favorites. 
    Nonfiction storytelling at its best, curated by the best.`,
  };
  const classes = useStyles();
  const formProps = {};

  const getData = async (searchedKey) => {
    if (!(searchedKey || search)) return;
    setIsLoading(true);
    setSearchedItem(searchedKey || search);
    const searchedData = await serviceHelper.searchVideo(searchedKey || search);

    if (searchedData && !searchedData.isError) {
      setData(searchedData.data);
    }

    setIsLoading(false);
  };

  const debounceSearch = (searchedKey) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getData(searchedKey)
    }, 500)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    debounceSearch(e.target.value);
  };

  if (search) {
    formProps.endAdornment = (
      <InputAdornment position="end">
        <CloseIcon onClick={() => setSearch("")} />
      </InputAdornment>
    );
  }
  const renderResult = () => {
    if (!searchedItem) {
      return <section/>;
    } else {
      return (
        <section id="header">
          <Grid container spacing={1}>
            <Typography variant="h6"
              gutterBottom style={{ color: "#BEBEBE" }}>
              {`Search result for "${searchedItem}"`}
            </Typography>
            {(data && data.videos && data.videos.length) ? (
              <Grid container spacing={1}>
                <VideoList content={data} fromSearchPage={true} />
              </Grid>)
              : <section style={{ height: '50vh' }} />}
          </Grid>
        </section>
      );
    }
  };
  return (
    <Layout location={location} seo={seo}>
      <TopNavigation location={location} />
      <section className={classes.section}>
        <Box className={classes.box}>
          <form onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              className={classes.formControl}
              variant="standard"
            >
              <OutlinedInput
                id="outlined-adornment-amount"
                placeholder="Search"
                autoComplete="off"
                value={search}
                className={classes.nameTextField}
                onChange={(e) => {
                  setSearch(e.target.value)
                  handleSubmit(e);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon onClick={handleSubmit} />
                  </InputAdornment>
                }
                {...formProps}
              />
              {isLoading && <LinearProgress classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary
              }} /> || null}
            </FormControl>
          </form>
        </Box>
        <Box mt={5} sx={{ flex: "1 1 auto" }}>
          {renderResult()}
        </Box>
      </section>

    </Layout>
  );
}

const useStyles = makeStyles((theme) => ({
  nameTextField: {
    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
  colorPrimary: {
    background: "black"
  },
  barColorPrimary: {
    background: "white"
  },
  section: {
    margin: '0 24px',
    marginTop: '16px',
    flex: '1 1 auto',
    [theme.breakpoints.between(0, 600)]: {
      margin: '0 16px'
    },
  },
  box: {
    marginTop: '20px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    padding: 8,
  },
  formControl: {
    margin: theme.spacing(1),
    background: "rgb(242, 242, 242)",
    borderRadius: "8px",
    width: "50vw",
    [theme.breakpoints.down("xs")]: {
      width: "95vw",
    },
    "&.Mui-selected": {
      outline: "none",
    },
  },
}));
